<template>
<div class="mt-5" >
  <div class="d-flex w-100">
                        <b-link :to="{name: 'Index'}">
                            <a class="btn ml-4  rounded-circle btn-circle font-20 card-body-list-cta" style="background:var(--color-broker);"  href="#"><i class="fas fa-arrow-left"></i></a>
                        </b-link>
                            <BasePageBreadCrumb :pages="pages" title="Política de privacidade" class="w-100 p-0 mt-2" :menu="menu"/>

                    </div>

    <div class="container-fluid mb-5 mt-3 pb-5 qsuite-c-fluid-0 p-dash-cliente-master">
        <div class="col-12">
            <div class="row">
                <div class="col-12 col-md-12 mb-5 col-lg-12 pt-3">
                     <div class="card mb-5 card-bg-dash">
                        <div class="card-body mb-5 pt-4 px-0 p-4"style="white-space: pre-line;"   v-html="termos">

                        </div>
                    </div>
                </div>   
            </div>
        </div>
    </div>
           <footer class="footer text-center text-muted p-0">
        <div class="col-12 py-3">
            <div class="col-12">
                <div class="row">
                    <div class="col-12 col-md-9 col-lg-10 text-center text-sm-left align-self-center mx-auto py-2">
                        © {{new Date(Date.now()).getFullYear()}} | QSuíte _Hub de integração de dados. Todos os direitos reservados. <a :href="'https://'+site+'/termos-de-uso'" class="a-link-1" style="text-decoration:none; color:var(--color-broker);">Termos de uso</a> | <a :href="'https://'+site+'/politica-de-privacidade'" class="a-link-1" style="text-decoration:none; color:var(--color-broker);">Política de privacidade</a> | <a href="https://r4marketing.com.br/termos-de-uso" class="a-link-1" style="text-decoration:none; color:var(--color-broker);">Termos de Uso</a>
                    </div>
                    <div class="col-12 col-md-3 col-lg-2 text-center text-sm-right align-self-center">
                        <a href="https://qsuite.com.br" target="_blank"><img src="@/assets/img/logo-gsuite.png" alt="Qsuite" style="max-height: 23px;"></a>
                    </div>
                </div>
            </div>
        </div>
    </footer>
</div>
</template>
<script>
import TermsPrivacyService from "@/services/resources/TermsPrivacyService";
import ThemeService from "@/services/resources/ThemeService";

const service = TermsPrivacyService.build();

const serviceTheme = ThemeService.build();

export default {
   data(){
       return{
         site:"",
          termos: "",
           company_id:"",
       }
            
   },
   computed:{
  
       isAdmin() {
        return this.$store.getters.isAdmin;
       }
   },
   methods:{
   
fetchTheme(){
            let data = {
                domain: window.location.hostname
            };

            serviceTheme
            .search(data)
            .then(resp => {
                resp.forEach(element => {
                    this.company_id = element.company_id;
                    switch(element.key){
                        case 'PORTAL_BROKER_SENTENCE':
                            this.sentence = element.value;
                            break;
                        case 'PORTAL_CLIENT_COMPONENT_COLOR':
                            this.component_color = element.value;
                            break;
                        case 'PORTAL_CLIENT_BACKGROUND_COLOR':
                            this.background_color = element.value;
                            break;
                        case 'PORTAL_CLIENT_ACTIVE_REGISTER':
                            this.active_register = element.value;
                            break;
                        case 'PORTAL_BROKER_BACKGROUND_DESKTOP':
                            this.desktop_background = `background-image: url("${element.value}")`;
                            break;
                        case 'PORTAL_BROKER_BACKGROUND_MOBILE':
                            this.mobile_background = `background-image: url("${element.value}")`;;
                            break;
                        case 'PORTAL_CLIENT_ACTIVE_REGISTER':
                            this.active_register = element.value;
                            break;
                    
                        case 'SETTINGS_LOGO':
                            this.logo = element.value;
                            break;
                              case 'BROKER_PRIVACY':
                            this.termos = element.value;
                            break;
                    }
                     
                });
           
            });
        },

   
   },
   mounted(){
    (function(h,o,t,j,a,r){
        h.hj=h.hj||function(){(h.hj.q=h.hj.q||[]).push(arguments)};
        h._hjSettings={hjid:4932490,hjsv:6};
        a=o.getElementsByTagName('head')[0];
        r=o.createElement('script');r.async=1;
        r.src=t+h._hjSettings.hjid+j+h._hjSettings.hjsv;
        a.appendChild(r);
     })(window,document,'https://static.hotjar.com/c/hotjar-','.js?sv=');
    this.site=window.location.host
       this.fetchTheme()
       
   }
}
</script>